.city-block {
  display: flex;
  flex-direction: row;
}

.sidebar-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
