.btn-primary {
  color: #fff;
  background-color: #4e73df;
}

#customnav {
  background-color: #2f2a2f;
}
.badge-primary {
  color: #fff;
  background-color: #4e73df;
}
