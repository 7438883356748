body {
  padding-top: 50px;
}

.button {
  max-height: 10px;
  font-size: 10px;
}

.search-warn {
  font-size: 8pt;
}
