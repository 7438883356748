.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

[contenteditable='true']:empty:not(:focus):before {
  content: attr(data-ph);
  color: grey;
  font-style: italic;
  content: 'Enter search here';
}

.inter-bar {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 25px;
}

.inter-bar2 {
  order: 1;
}

.exit {
  font-size: 8pt;
  align-self: flex-end;
  margin-left: 1em;
}

.search-query {
  display: flex;
  max-width: 10em;
  min-width: max-content;
  margin-right: 0.3em;
}

.search-filter-term {
  display: flex;
  max-width: 10em;
  min-width: max-content;
  margin-right: 0.3em;
  color: #fff;
}

.remove-query {
  cursor: pointer;
  margin-left: auto;
  align-self: flex-end;
}

.remove-query:hover {
  color: rgb(245, 53, 53);
}

.border {
  border: 1px solid black;
}

.float-right {
  float: right;
}
